import { useContext } from 'react';

import { Box, Hidden, IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';

import HeaderMenu from './Menu';
import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';
import Logo from 'src/components/Logo3';
import { useGlobalState } from 'src/state/globalstate';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 5;
        background-color: ${theme.header.background};
        box-shadow: ${theme.header.boxShadow};
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`
);

function Header() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const toggleStatus = useGlobalState('toggleStatus')
  return (
    <HeaderWrapper id="mobile-header-wrapper2" display="flex" alignItems="center"  style={{ left: !toggleStatus[0] ? '1.5%':'' }}>
      <Box display="flex" alignItems="center"   className="MobileHeader-menu-wrapper">
        <Hidden lgUp>
          <Logo />
        </Hidden>
        {/* <Hidden mdDown>
          <HeaderMenu />
        </Hidden> */}
      </Box>
      <Box display="flex" alignItems="left"  className="MobileHeader-wrapper">
        {/* <HeaderButtons /> */}
        <p style={{ fontSize: '14px', margin: '0px', padding: '2px 0px' }}>
        <b>Welcome to the AMAC Voters Leaderboard </b><br></br>
        An interactive tool for exploring voter turnout trends among Muslims in Washington State. Apply filters on all data and charts to better understand voters' location, district, ethnicity.<br></br>
        Ballot returns for the 2024 General Election will be updated each weekday by midnight.
        </p>
        {/* <HeaderUserbox /> */}
        {/* <Hidden lgUp>
          <Tooltip arrow title="Toggle Menu">
            <IconButton color="primary" onClick={toggleSidebar}>
              {!sidebarToggle ? <MenuTwoToneIcon /> : <CloseTwoToneIcon />}
            </IconButton>
          </Tooltip>
        </Hidden> */}
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
