import { FC, ReactNode, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

import Sidebar from './Sidebar';

import Header from './Header';
import SidebarMinimize from './SidebarMinimize';
import { useGlobalState } from 'src/state/globalstate';

interface SidebarLayoutProps {
  children?: ReactNode;
}

const MainWrapper = styled(Box)(
  ({ theme }) => `
        flex: 1 1 auto;
        display: flex;
        height: 100%;
        
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            padding-left: ${theme.sidebar.width};
        }
`
);

const MainContent = styled(Box)(
  ({ theme }) => `
        margin-top: ${theme.header.height};
        flex: 1 1 auto;
        overflow: auto;
`
);

const SidebarLayout: FC<SidebarLayoutProps> = () => {
  const toggleStatus = useGlobalState('toggleStatus')
  const [toggle, settoggle] = useState(true)
  // if(toggleStatus){
  //   console.log('toggleStatus')
  //   console.log(toggleStatus[0])
  //   // if(toggle){
  //   //   console.log('Main False called')
  //   //   settoggle(false)
  //   // } else if(!toggle){
  //   //   console.log('Main True called')
  //   //   settoggle(true)
  //   // }
  // }
  return (
    <>
      {toggleStatus[0] ? (
        <Sidebar />
      ):( 
        <SidebarMinimize />
      )}
      
      
      <MainWrapper 
      style={{ paddingLeft: !toggleStatus[0] ?  '1.5%' : ''}}
      >
        <Header />
        <MainContent>
          <Outlet />
        </MainContent>
      </MainWrapper>
    </>
  );
};

export default SidebarLayout;
