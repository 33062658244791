import { useContext, useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { SidebarContext } from 'src/contexts/SidebarContext';
import Logo from 'src/components/Logo2';

import { Box, Drawer, Hidden } from '@mui/material';

import { styled } from '@mui/material/styles';
import SidebarMenu from './SidebarMenu';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftSharpIcon from '@mui/icons-material/ArrowCircleLeftSharp';
import { setGlobalState, useGlobalState } from 'src/state/globalstate';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        height: 100%;
        
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            position: fixed;
            z-index: 10;
            border-top-right-radius: ${theme.general.borderRadius};
            border-bottom-right-radius: ${theme.general.borderRadius};
        }
`
);

const TopSection = styled(Box)(
  ({ theme }) => `
        display: flex;
        height: 88px;
        align-items: center;
        margin: 0 ${theme.spacing(2)} ${theme.spacing(2)};
        border-bottom: ${theme.sidebar.dividerBg} solid 1px;
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const [toggle, settoggle] = useState(true);
  const toggleStatus = useGlobalState('toggleStatus');

  useEffect(() => {
    const keyPressHandler = (event) => {
    
      if (event.key === '[') {
        setGlobalState('toggleStatus', false);
        settoggle(false);
      } else if (event.key === ']') {
  
        setGlobalState('toggleStatus', true);
        settoggle(true);
      }
    };
    window.addEventListener('keydown', keyPressHandler);
  }, []);

  const toggleSidebars = async () => {
    if (toggle) {
      console.log('False called');
      setGlobalState('toggleStatus', false);
      settoggle(false);
    } else if (!toggle) {
      console.log('True called');
      setGlobalState('toggleStatus', true);
      settoggle(true);
    }
  };

  return (
    <>
      <Hidden lgDown>
        <SidebarWrapper>
        <div
            onClick={toggleSidebars}
            style={{
              cursor: 'pointer',
              float: 'right',
              top: '10%',
              position: 'relative'
            }}
          >
            {toggle ? (
              <ArrowCircleLeftIcon
                className="Toogle-sidebar"
                style={{
                  cursor: 'pointer',
                  fontSize: '30px',
                  zIndex: '9999',
                  position: 'absolute',
                  marginLeft: '-16px'
                }}
              />
            ) : (
              <ArrowCircleRightIcon
                className="Toogle-sidebar"
                style={{
                  cursor: 'pointer',
                  fontSize: '30px',
                  zIndex: '9999',
                  position: 'absolute',
                  marginLeft: '-16px'
                }}
              />
            )}{' '}
            {/* <span style={{ marginLeft: '10px', fontWeight: '600' }}>
                Collapse Menu
              </span> */}
          </div>
          <Scrollbars autoHide>
            <TopSection>
              <Logo />
            </TopSection>
            <SidebarMenu />
          </Scrollbars>
        </SidebarWrapper>
      </Hidden>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggle}
          onClose={closeSidebar}
          variant="temporary"
          elevation={9}
        >
          <SidebarWrapper>
            <Scrollbars autoHide>
              <TopSection>
                <Logo />
              </TopSection>
              <SidebarMenu />
            </Scrollbars>
          </SidebarWrapper>
        </Drawer>
      </Hidden>
    </>
  );
}

export default Sidebar;
