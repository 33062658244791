import { ListSubheader, List, ListItemIcon } from '@mui/material';
import { useLocation, matchPath } from 'react-router-dom';
import SidebarMenuItem from './item';
import { styled } from '@mui/material/styles';
import {
  Alert,
  Collapse,
  DialogContentText,
  Link,
  Tooltip,
  Box,
  Grid,
  Container,
  MenuItem
} from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import styles from './rider.module.scss';
import { SyntheticEvent, useEffect, useState } from 'react';
import Axios, { API } from 'src/common/api';
import { AddDocResp, AddFileResp } from 'src/types/api';
import { setGlobalState, useGlobalState } from 'src/state/globalstate';
import { toast } from 'react-toastify';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm } from 'react-hook-form';
import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Label from 'src/components/Label';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { NavLink, useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { AmacUrl } from 'src/common/routes';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  autoFocus: false
};

enum AlertMessageType {
  Success = 'success',
  Error = 'error'
}

type AlertMessage = { type?: AlertMessageType; message?: string };

interface CountryFilterPanelProps {
  onFilterChange: (selectedCountries: string[]) => void;
}

function SidebarMenu() {
  const location = useLocation();
  const email = localStorage.getItem('email');
  const URL = window.location.href;

  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<AlertMessage>({});

  const displayAlert = (type: AlertMessageType, message: string) => {
    setShowAlert(true);
    setAlertMessage({ type, message });

    // Hide alert after 8 seconds
    setTimeout(() => {
      setShowAlert(false);
    }, 8000);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({ mode: 'onChange' });

  const [personName, setPersonName] = useState([]);
  const [selectedCountry, setselectedCountry] = useState<string[]>([]);
  const [countryListingAll, setcountryListingAll] = useState([]);
  const [countryListingDisplayed, setcountryListingDisplayed] = useState([]);
  // console.log('countryListingAll ===');
  // console.log(countryListingAll);

  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const toggleSection = () => setIsExpanded(!isExpanded);

  const handleCountryChanges = (country: string) => {
    const updatedCountries = selectedCountries.includes(country)
      ? selectedCountries.filter((c) => c !== country)
      : [...selectedCountries, country];

    const coomaSpearatedCountries: string = updatedCountries.join(', ');
    setGlobalState('filtersCountries', coomaSpearatedCountries);
    setSelectedCountries(updatedCountries);
    // onFilterChange(updatedCountries);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    const searchParam = e.target.value;
    console.log('searchParam ==> ' + searchParam);
    const filteredCountries = countryListingDisplayed.filter((search) =>
      search.toLowerCase().includes(searchParam.toLowerCase())
    );
    console.log('Search Countries ===');
    console.log(filteredCountries);
    setcountryListingAll(filteredCountries);
  };

  const handleSelectAll = (selectAll: boolean) => {
    const updatedCountries = selectAll ? [...countryListingAll] : [];
    const coomaSpearatedCountries: string = updatedCountries.join(', ');
    setGlobalState('filtersCountries', coomaSpearatedCountries);
    setSelectedCountries(updatedCountries);
    // onFilterChange(updatedCountries);
  };

  const handleCountryChange = (
    event: SelectChangeEvent<typeof selectedCountry>
  ) => {
    const {
      target: { value }
    } = event;
    if (value[value.length - 1] === 'all') {
      setselectedCountry(
        selectedCountry.length === countryListingAll.length
          ? []
          : countryListingAll
      );
      console.log('selectedCountries All === ');
      console.log(
        selectedCountry.length === countryListingAll.length
          ? []
          : countryListingAll
      );
      const allCountriesArray: string[] =
        selectedCountry.length === countryListingAll.length
          ? []
          : countryListingAll;
      const selectedCountries: string = allCountriesArray.join(', ');
      setGlobalState('filtersCountries', selectedCountries);
      console.log('selectedCountries 01 ==> ' + selectedCountries);
      return;
    } else {
      setselectedCountry(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value
      );
      const selectedCountries =
        typeof value === 'string' ? value.split(',') : value;

      const allCountriesArray: string[] = selectedCountries;
      const updatedSelectedCountries: string = allCountriesArray.join(', ');
      setGlobalState('filtersCountries', updatedSelectedCountries);

      console.log('selectedCountries 02 => ' + updatedSelectedCountries);
    }
    // const formData = new FormData();
    // formData.append('city', city);

    // try {
    //   const res = await Axios.post(API.votingData, formData);
    //   const message = res.data;
    //   console.log(message)
    // } catch(e) {
    //   console.log(e)
    // }
  };

  useEffect(() => {
    // getCountries
    // getCountries
    const getCountries = async () => {
      try {
        await Axios.get(API.getCountries).then((res) => {
          // store the data into our books variable
          const countryArray = JSON.parse(res.data[0].country);
          console.log('countryArray ===');
          console.log(countryArray);
          setGlobalState('countryListing', countryArray);
          countryArray.push('Palestine');
          countryArray.push('Pakistan');
          countryArray.push('India');
          countryArray.push('Bangladesh');
          countryArray.push('');
          countryArray.sort(function (a, b) {
            var nameA = a.toLowerCase(),
              nameB = b.toLowerCase();
            if (nameA < nameB)
              //sort string ascending
              return -1;
            if (nameA > nameB) return 1;
            return 0; //default return value (no sorting)
          });
          console.log('countryArray');
          setcountryListingAll(countryArray);
          setcountryListingDisplayed(countryArray);
          setselectedCountry(countryArray);
          setSelectedCountries(countryArray);
          const allCountriesArray: string[] = countryArray;
          const updatedSelectedCountries: string = allCountriesArray.join(', ');
          // setGlobalState('filtersCountries', updatedSelectedCountries);
        });
      } catch (err) {
        console.log(err);
      }
    };
    getCountries();
  }, []);

  const onSubmit = async (params) => {
    console.log('Params');
    console.log(params);
    return false;
  };

  return (
    <>
      <div
        style={{
          padding: '25px'
          // display: URL.includes(AmacUrl.Statistics) ? 'none' : 'block'
        }}
      >
        <h3>Filters</h3>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Grid
            container
            direction="row"
            justifyContent="left"
            alignItems="stretch"
            spacing={1}
          >
            <Grid item xs={12} sm={12}>
              <div className="country-filter-panel">
                <div className="filter-header">
                  {/* <h3>Country of Origin</h3> */}
                  {/* Select All Checkbox */}
                  <input
                    type="checkbox"
                    checked={
                      selectedCountries.length === countryListingAll.length
                    }
                    onChange={(e) => handleSelectAll(e.target.checked)}
                  />
                  <label onClick={toggleSection}>Country of Origin</label>
                  <span style={{ float: 'right' }}>
                    {isExpanded ? (
                      <ArrowDropUpIcon
                        style={{ fontSize: '22px', verticalAlign: 'middle' }}
                        onClick={toggleSection}
                      />
                    ) : (
                      <ArrowDropDownIcon
                        style={{ fontSize: '22px', verticalAlign: 'middle' }}
                        onClick={toggleSection}
                      />
                    )}
                  </span>
                </div>

                {isExpanded && (
                  <>
                    <div
                      className="country-options"
                      style={{ scrollbarWidth: 'none', marginTop: '5px' }}
                    >
                      {/* Search Input */}
                      <div className="search-input">
                        <SearchIcon className="mui-search-icon" />
                        <input
                          type="text"
                          placeholder="Search countries..."
                          value={searchTerm}
                          onChange={handleSearchChange}
                          className="search-input-wrapper"
                        />
                      </div>
                    </div>

                    <div className="country-options">
                      {/* Select All Checkbox */}
                      {/* <label>
                        <input
                          type="checkbox"
                          checked={
                            selectedCountries.length ===
                            countryListingAll.length
                          }
                          onChange={(e) => handleSelectAll(e.target.checked)}
                        />
                        Select All
                      </label> */}

                      {/* All Options */}
                      <span style={{ color: '#000', fontSize: '12px' }}>
                        {' '}
                        <i>Popular</i>
                      </span>
                      {countryListingAll.map((country) => {
                        if (
                          country == 'Palestine' ||
                          country == 'Pakistan' ||
                          country == 'India' ||
                          country == 'Bangladesh'
                        ) {
                          return (
                            <>
                              <label key={country}>
                                <input
                                  type="checkbox"
                                  checked={selectedCountries.includes(country)}
                                  onChange={() => handleCountryChanges(country)}
                                />
                                {country}
                              </label>
                            </>
                          );
                        }
                      })}
                      <span style={{ color: '#000', fontSize: '12px' }}>
                        {' '}
                        <i>Other</i>
                      </span>
                      {/* Individual Country Checkboxes */}
                      {countryListingAll.map((country) => {
                        if (country == '') {
                          return (
                            <>
                              <label key={country}>
                                <input
                                  type="checkbox"
                                  checked={selectedCountries.includes(country)}
                                  onChange={() => handleCountryChanges(country)}
                                />
                                Unknown Ethnicity
                              </label>
                            </>
                          );
                        } else if (
                          country != 'Palestine' &&
                          country != 'Pakistan' &&
                          country != 'India' &&
                          country != 'Bangladesh'
                        ) {
                          return (
                            <>
                              <label key={country}>
                                <input
                                  type="checkbox"
                                  checked={selectedCountries.includes(country)}
                                  onChange={() => handleCountryChanges(country)}
                                />
                                {country}
                              </label>
                            </>
                          );
                        }
                      })}
                    </div>
                  </>
                )}
              </div>
              {/* <FormControl
                sx={{
                  width: '100%',
                  mt: 0
                }}
              >
                <InputLabel id="demo-multiple-checkbox-label">
                Country of Origin
                </InputLabel>
                <Select
                  {...register('country')}
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  fullWidth
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  className={styles.inputVar}
                  input={<OutlinedInput label="Country of Origin" />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="all">
                    <ListItemIcon>
                      <Checkbox
                        checked={
                          countryListingAll.length > 0 &&
                          selectedCountry.length === countryListingAll.length
                        }
                        indeterminate={
                          selectedCountry.length > 0 &&
                          selectedCountry.length < countryListingAll.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary="Select All" />
                  </MenuItem>
                  <ListSubheader style={{ color: '#000' }}>
                    Popular{' '}
                  </ListSubheader>
                  {countryListingAll.map((option) => {
                    if (
                      option == 'Palestine' ||
                      option == 'India' ||
                      option == 'Bangladesh' ||
                      option == 'Pakistan'
                    ) {
                      return (
                        <MenuItem key={option} value={option}>
                          <Checkbox
                            checked={selectedCountry.includes(option)}
                          />
                          <ListItemText primary={option} />
                        </MenuItem>
                      );
                    }
                  })}
                  <ListSubheader style={{ color: '#000' }}>
                    Other{' '}
                  </ListSubheader>
                  {countryListingAll.map((option) => {
                    if (
                      option != 'Palestine' &&
                      option != 'India' &&
                      option != 'Bangladesh' &&
                      option != 'Pakistan'
                    ) {
                      return (
                        <MenuItem key={option} value={option}>
                          <Checkbox
                            checked={selectedCountry.includes(option)}
                          />
                          <ListItemText primary={option} />
                        </MenuItem>
                      );
                    }
                  })}
                </Select>
              </FormControl> */}
            </Grid>
          </Grid>
        </form>
      </div>
    </>
  );
}

export default SidebarMenu;
