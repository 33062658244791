import { ReactNode } from 'react';

import { AmacUrl } from 'src/common/routes';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import DescriptionIcon from '@mui/icons-material/Description';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: '',
    items: [
      {
        name: 'Voters Data',
        link: AmacUrl.Dashboard,
        icon: HowToVoteIcon
      }
    ]
  },
  {
    heading: '',
    items: [
      {
        name: 'Analytics',
        link: AmacUrl.Analytics,
        icon: AnalyticsIcon
      }
    ]
  },
  {
    heading: '',
    items: [
      {
        name: 'Users Management',
        link: AmacUrl.UsersManagement,
        icon: GroupsIcon
      }
    ]
  },
  // {
  //   heading: '',
  //   items: [
  //     {
  //       name: 'Search Voters - Postgres',
  //       link: AmacUrl.Voters,
  //       icon: PersonSearchIcon
  //     }
  //   ]
  // },
  {
    heading: '',
    items: [
      {
        name: 'Voters Listing Logs',
        link: AmacUrl.LoginLogs,
        icon: DescriptionIcon
      }
    ]
  },
  {
    heading: '',
    items: [
      {
        name: 'Voters Data - Old',
        link: AmacUrl.SearchUserClickHouse,
        icon: PersonSearchIcon
      }
    ]
  },
  {
    heading: '',
    items: [
      {
        name: 'Voters Listing Logs - Old',
        link: AmacUrl.VotersListingLogs,
        icon: DescriptionIcon
      }
    ]
  }
  
  
];

export default menuItems;
